<template lang="pug">
    v-container.negro( style='position: relative' v-if='Pie_de_página' fluid).pa-0.ma-0
      v-row.negro.mt-12.mb-12
        Columna_mapa_de_sitio
        Columna_de_nuestro_contacto
        Columna_de_encuentranos_en
      v-divider.primario.divider.mt-12.mb-3
</template>
<script>
export default {
  components: {
    Columna_mapa_de_sitio: ()=>import('./mapa_de_sitio/columna_mapa_de_sitio'),
    Columna_de_nuestro_contacto: ()=>import('./nuestro_contacto/columna_de_nuestro_contacto'),
    Columna_de_encuentranos_en: ()=>import('./encuéntranos_en/columna_de_encuéntranos_en'),
  },
}
</script>
<style lang="sass">
  .divider
    max-height: 4px!important
    height: 4px!important
</style>